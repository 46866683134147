<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<el-form ref="form" :model="form" :rules="rules" label-width="100px" label-position="left">
				<el-form-item label="头像" prop="username" style="width: 450px;">
				   <el-upload
				     class="upload-border"
				     action="/manage/images/upload"
				     :show-file-list="false"
				     :on-success="uploadSuccess">
				     <img v-if="form.avatar != ''" :src="form.url" class="w-100 h-100">
				     <i v-else class="el-icon-plus uploader-icon"></i>
				   </el-upload>
				</el-form-item>
				<el-form-item label="账号名称" prop="username" style="width: 450px;">
				   <el-input v-model="form.username" placeholder="请输入账号名称" size="small" clearable></el-input>
				</el-form-item>
				<el-form-item label="教师编码" prop="code" style="width: 450px;" v-if="form.id != 100">
				   <el-input v-model="form.code" placeholder="请输入教师编码" size="small" clearable></el-input>
				</el-form-item>
				<el-form-item label="账号密码" prop="password" style="width: 450px;">
				   <el-input v-model="form.password" placeholder="请输入账号密码" size="small" clearable></el-input>
				   <small class="text-danger font-s">为空表示不修改密码</small>
				</el-form-item>
				<el-form-item label="手机号码" prop="mobile" style="width: 450px;">
				   <el-input v-model="form.mobile" placeholder="请输入手机号码" type="number" size="small" clearable></el-input>
				</el-form-item>
				<el-form-item label="性别" prop="gender" style="width: 450px;">
				   <el-radio-group v-model="form.gender">
				   	<el-radio :label="1">男</el-radio>
				   	<el-radio :label="2">女</el-radio>
				   	<el-radio :label="3">未知</el-radio>
				   </el-radio-group>
				</el-form-item>
				<el-form-item label="年龄" prop="age" style="width: 450px;">
				   <el-input v-model="form.age" placeholder="请输入年龄" type="number" size="small" clearable></el-input>
				</el-form-item>
				<el-form-item label="年级" prop="nianji" style="width: 450px;">
				  <el-select v-model="form.nianji" placeholder="请选择年级" style="width: 100%;" disabled>
				    <el-option :value="0" label="无"/>
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="学科" prop="xueke" style="width: 450px;">
				  <el-select v-model="form.xueke" placeholder="请选择学科" style="width: 100%;" disabled>
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in xueke" :key="item.id"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="民族" prop="nation" style="width: 450px;">
				   <el-input v-model="form.nation" placeholder="请输入民族" type="text" size="small" clearable></el-input>
				</el-form-item>
				<el-form-item label="毕业院校" prop="by_school" style="width: 450px;">
				   <el-input v-model="form.by_school" placeholder="请输入毕业院校" type="text" size="small" clearable></el-input>
				</el-form-item>
				<el-form-item label="职称" prop="zc" style="width: 450px;">
					<el-select v-model="form.zc" placeholder="请选择学历" style="width: 100%;">
					  <el-option :value="item.name" :label="item.name" v-for="(item,index) in zc_list" :key="item.id"/>
					</el-select>
				</el-form-item>
				<el-form-item label="最高学历" prop="xl" style="width: 450px;">
					<el-select v-model="form.xl" placeholder="请选择学历" style="width: 100%;">
					  <el-option :value="item.name" :label="item.name" v-for="(item,index) in xl_list" :key="item.id"/>
					</el-select>
				</el-form-item>
				<el-form-item label="从业时间" prop="work_date" style="width: 450px;">
				  <el-date-picker
					v-model="form.work_date"
					type="date"
					value-format="yyyy-MM-dd"
					class="w-100"
					placeholder="请选择从业时间">
				  </el-date-picker>
				</el-form-item>
				<el-form-item label="出生日期" prop="birthday" style="width: 450px;">
				  <el-date-picker
					v-model="form.birthday"
					type="date"
					value-format="yyyy-MM-dd"
					class="w-100"
					placeholder="请选择出生日期">
				  </el-date-picker>
				</el-form-item>
				<el-form-item>
				   <el-button type="primary" size="small" @click="submit" style="width: 250px;">确定</el-button>
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: '',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					username:'',
					password:'',
					code:'',
					mobile:'',
					gender:3,
					nianji:'',
					xuke:'',
					status:1,
					avatar:'',
					url:'',
					work_date:'',
					birthday:'',
					nation:'',
					by_school:'',
					zc:'',
					xl:'',
				},
				rules:{
					username: [
					  { required: true, message: '请输入名称', trigger: 'blur' }
					],
					mobile: [
					  { required: true, message: '请输入手机号码', trigger: 'blur' }
					],
					nation: [
					  { required: true, message: '请输入民族', trigger: 'blur' }
					],
					by_school: [
					  { required: true, message: '请输入毕业院校', trigger: 'blur' }
					],
					zc: [
					  { required: true, message: '请输入职称', trigger: 'blur' }
					],
					xl: [
					  { required: true, message: '请输入最高学历', trigger: 'blur' }
					],
					birthday: [
					  { required: true, message: '请选择出生日期', trigger: ['blur', 'change'] }
					],
					work_date: [
					  { required: true, message: '请选择从业时间', trigger: ['blur', 'change'] }
					],
					// nianji: [
					//   { required: true, message: '请选择年级', trigger: ['blur', 'change'] }
					// ],
					// xueke: [
					//   { required: true, message: '请选择学科', trigger: ['blur', 'change'] }
					// ],
				},
				nianji:[],
				xueke:[],
				info:{},
				xl_list:[
					{id:1,name:'专科'},
					{id:2,name:'本科'},
					{id:3,name:'研究生'},
					{id:4,name:'博士'},
				],
				zc_list:[
					{id:1,name:'正高级教师'},
					{id:2,name:'高级教师'},
					{id:3,name:'一级教师'},
					{id:4,name:'二级教师'},
					{id:5,name:'三级教师'},
				],
			}
		},
		mounted() {
			this.getNianji()
			this.getXueke()
			this.getAdminInfo()
		},
		methods:{
			getAdminInfo(){
				this.axios.get('/manage/admin/info').then(res=>{
					if(res.status){
						this.info = res.data
						this.form.id = res.data.id
						this.form.username = res.data.username
						this.form.code = res.data.code
						this.form.mobile = res.data.mobile
						this.form.gender = res.data.gender
						this.form.age = res.data.age
						this.form.nianji = res.data.nianji
						this.form.work_date = res.data.work_date
						this.form.xueke = res.data.xueke != 0 ? res.data.xueke : ''
						this.form.avatar = res.data.avatar
						this.form.url = res.data.url
						this.form.birthday = res.data.birthday
						this.form.nation = res.data.nation
						this.form.by_school = res.data.by_school
						this.form.zc = res.data.zc
						this.form.xl = res.data.xl
					}
				})
			},
			getNianji(){
				this.axios.get('/manage/nianji/all').then(res=>{
					if(res.status){
						this.nianji = res.data
					}
				})
			},
			getXueke(){
				this.axios.get('/manage/xueke/all').then(res=>{
					if(res.status){
						this.xueke = res.data
					}
				})
			},
			uploadSuccess(e){
			   if(e.status){
				 this.form.avatar = e.data.id
				 this.form.url = e.data.url
			   }
			},
			reset() {
			  this.form = {
			   username:'',
			   password:'',
			   code:'',
			   mobile:'',
			   gender:3,
			   nianji:'',
			   xueke:'',
			   status:1,
			   avatar:'',
			   url:'',
			   work_date:'',
			   birthday:'',
			   nation:'',
			   by_school:'',
			   zc:'',
			   xl:'',
			  }
			  this.resetForm('form')
			},
			submit(){
				this.$refs.form.validate(valid => {
				  if (valid) {
					  this.axios.post('/manage/admin/toEdit/', this.form).then(res => {
					    if (res.status) {
					      this.$message.success('修改成功')
					    } else {
					      this.$message.error(res.msg)
					    }
					  })
				  }
				})
			},
		}
	}
</script>
<style>
</style>